import React from 'react'
import PropTypes from 'prop-types';
import { openPopupWidget } from "react-calendly"

import Button from '../Button';

const CalendlyButton = ({ btnStyle, label }) => {

  const handleCalendly = () => {
    openPopupWidget(calendlyData)
  };

  const calendlyData = {
    url: "https://calendly.com/portaregroup/experto",
    prefill: {},
    pageSettings: {},
    utm: {}
  }

  return (
    <Button 
      onClick={handleCalendly} 
      {...btnStyle}
      title={label}
    />
  )
}

CalendlyButton.propTypes = {
  btnStyle: PropTypes.object,
};

CalendlyButton.defaultProps = {
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['15px', '16px'],
    colors: 'main',
    backgroundColor: 'main',
  }
};

export default CalendlyButton;