import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Fade from 'react-reveal/Fade';
import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { play } from 'react-icons-kit/entypo/play';
import { openModal, closeModal } from '@redq/reuse-modal';

import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import RImage from '../../common/components/ResponsiveImage';
import Button from '../../common/components/Button';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/Container';
import FeatureBlock from '../../common/components/FeatureBlock';
import CalendlyButton from '../../common/components/CalendlyButton';
import Particles from '../Particle';
import BannerWrapper, { BannerObject, 
                        VideoModal, 
                        PlayButton, 
                        VideoWrapper 
} from './bannerSection.style';

const BannerSection = ({ row, col, title, btnStyle, description }) => {

  const DATA = useStaticQuery(graphql`
    query {
      dataJson {
        HEADERDATA {
          headerTitle
          subtitle
          button
          videoURL
          altImage
          headerImage {
            childImageSharp{
              fixed(width: 640, height: 610) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);

  const ModalContent = () => (
    <VideoWrapper>
      <iframe
        title="Video"
        src={videoURL}
        frameBorder="0"
        allow='autoplay'
      />
    </VideoWrapper>
  );

  const CloseModalButton = () => (
    <Button
      className="modalCloseBtn"
      variant="fab"
      onClick={() => closeModal()}
      icon={<i className="flaticon-plus-symbol" />}
    />
  );

  const handleVideoModal = () => {
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
        width: '100%',
        height: '100%',
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };

  const { headerTitle, 
          subtitle, 
          button, 
          videoURL, 
          headerImage,
          altImage
  } = DATA.dataJson.HEADERDATA;
  
  const ButtonGroup = () => (
    <Fragment>
      <CalendlyButton {...btnStyle} label={button} />
    </Fragment>
  );

  return (
    <BannerWrapper id="banner_section">
      <Particles />
      <Container style={{ 'zIndex': '1' }}  >
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={
                <Heading content={headerTitle} {...title} />
              }
              description={
                <Text content={subtitle} {...description} />
              }
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
      <BannerObject style={{ 'zIndex': '2' }}>
        <div className="objectWrapper">
          <div className="dashboardWrapper">
            <Box>
              <Fade bottom>
                <VideoModal>
                  <RImage fixed={headerImage.childImageSharp.fixed} alt={altImage} />
                  <PlayButton tabIndex="0" onClick={handleVideoModal}>
                    <Icon icon={play} size={40} />
                  </PlayButton>
                </VideoModal>
              </Fade>
            </Box>
          </div>
        </div>
      </BannerObject>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
};

Button.defaultProps = {
  disabled: false,
  isMaterial: false,
  isLoading: false,
  isMain: true,
  type: 'button'
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['45px', '49px', '49px', '60px', '60px'],
    fontWeight: '700',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.1',
  },
  description: {
    fontSize: '16px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['15px', '16px'],
    colors: 'main',
    backgroundColor: 'main',
  }
};

export default BannerSection;
