import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Text from '../../common/components/Text';
import RImage from '../../common/components/ResponsiveImage';
import CustomerWrapper, { ImageWrapper } from './customer.style';

const Customer = ({textStyle}) => {

  const DATA = useStaticQuery(graphql`
    query {
      dataJson {
        CLIENTS {
          title
          clients{
            id
            title
            image {
              childImageSharp{
                fixed {
                  ...GatsbyImageSharpFixed
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  `);

  const { title, clients } = DATA.dataJson.CLIENTS;
  
  const Images = () => (
    clients.map((client) => (
      <RImage fixed={client.image.childImageSharp.fixed} alt={client.title} style={{ overflow: 'initial' }} />
    ))
  );

  return (
    <CustomerWrapper>
      <Text content={title} {...textStyle} />
      <ImageWrapper>
        <Images />
      </ImageWrapper>
    </CustomerWrapper>
  );
};

Customer.propTypes = {
  textStyle: PropTypes.object
};

Customer.defaultProps = {
  textStyle: {
    fontWeight: 'bolder'
  }
}

export default Customer;
