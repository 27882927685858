import React from "react";
import '@redq/reuse-modal/es/index.css';

import SEO from "../components/seo"
import Customer from '../containers/Customer';
import FaqSection from '../containers/FaqSection';
import Testimonial from '../containers/Testimonial';
import CallToAction from '../containers/CallToAction';
import BannerSection from '../containers/BannerSection';
import PartnerSection from '../containers/PartnerSection';
import FeatureSection from '../containers/FeatureSection';
import TimelineSection from '../containers/TimelineSection';

const IndexPage = () => (
  <>
    <SEO /> 
    <BannerSection />
    <Customer />
    <TimelineSection />
    <FeatureSection />
    <Testimonial />
    <PartnerSection />
    <FaqSection />
    <CallToAction /> 
  </>
)

export default IndexPage
